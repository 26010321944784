body .embeddedServiceSidebar.layout-docked .dockableContainer {
  width: 360px;
}
body .embeddedServiceSidebar,
body
  .embeddedServiceSidebar
  .embeddedServiceSidebarFormField
  .uiInput
  .uiLabel-left,
body .embeddedServiceSidebar .embeddedServiceSidebarButton span,
body .embeddedServiceSidebar .headerText {
  font-family: proxima-nova, open-sans, Arial, Helvetica, sans-serif;
}
body
  .embeddedServiceSidebar
  .embeddedServiceSidebarFormField
  .uiInput
  .uiLabel-left {
  font-size: 13px;
}
body .embeddedServiceSidebar.layout-docked .dockableContainer,
body .embeddedServiceSidebar .embeddedServiceSidebarHeader,
body .embeddedServiceSidebar .embeddedServiceSidebarHeader .shortHeader {
  border-radius: 0px;
}
body .embeddedServiceSidebar .embeddedServiceSidebarHeader {
  background-color: #333333;
}
body .embeddedServiceSidebar .embeddedServiceSidebarForm .fieldList {
  margin-top: 15px;
}
body .embeddedServiceSidebar .headerText {
  margin: 0;
  font-weight: 500;
  letter-spacing: 1px;
}
body
  .embeddedServiceSidebar
  .embeddedServiceSidebarFormField
  .uiInput
  .required {
  position: static;
  margin-left: 2px;
  color: #666666;
}

body
  .embeddedServiceSidebar
  .embeddedServiceSidebarFormField
  .slds-style-inputtext,
body
  .embeddedServiceSidebar
  .embeddedServiceSidebarFormField
  .slds-style-select,
body
  .embeddedServiceSidebar
  .embeddedServiceSidebarForm
  .fieldList
  .uiInputText:last-child
  input {
  border-radius: 0px;
}
body .embeddedServiceSidebar .embeddedServiceSidebarButton {
  border-radius: 0px;
  background-color: #000;
}
body .embeddedServiceSidebar .embeddedServiceSidebarButton span {
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 14px;
}

body
  .embeddedServiceSidebar
  .embeddedServiceSidebarForm
  .embeddedServiceSidebarFormField {
  margin-top: 10px;
}

body
  .embeddedServiceSidebar
  .embeddedServiceSidebarFormField
  .split-field-container,
body .embeddedServiceSidebar .embeddedServiceSidebarFormField .uiInput--input,
body .embeddedServiceSidebar .embeddedServiceSidebarFormField .uiInput--select {
  margin-bottom: 0px;
}

body
  .embeddedServiceSidebar
  .embeddedServiceSidebarFormField
  .uiInputSelect
  select.select,
body .embeddedServiceSidebar .embeddedServiceSidebarHeader .headerItem {
  cursor: pointer;
}

body
  .embeddedServiceSidebar
  .embeddedServiceSidebarHeader
  .headerItem:focus:before,
body
  .embeddedServiceSidebar
  .embeddedServiceSidebarHeader
  .headerItem:hover:before {
  border-radius: 0px;
}

/* Minimized anchor */
body .embeddedServiceHelpButton {
  z-index: 1001;
}
body .embeddedServiceHelpButton .helpButton {
  bottom: 25px;
  right: -2px;
}
body .embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #444;
  border-radius: 0px;
  font-family: CaslonRHThin, proxima-nova, open-sans, Arial, Helvetica,
    sans-serif;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  border: 1px solid #545454;
  height: 50px;
  padding: 0 12px;
}
body .embeddedServiceHelpButton .helpButton .uiButton .message {
  -webkit-font-smoothing: antialiased;
}
body .embeddedServiceHelpButton .helpButton .uiButton .message::after {
  content: "WE’RE HERE TO HELP YOU 24/7";
  display: block;
  font-size: 11px;
  letter-spacing: 0.5px;
}
body .embeddedServiceHelpButton .uiButton {
  min-width: 16em;
  max-width: 17em;
}
body .embeddedServiceHelpButton .embeddedServiceIcon {
  display: none !important;
}
body .embeddedServiceHelpButton .helpButton .helpButtonEnabled:hover::before,
body .embeddedServiceHelpButton .helpButton .helpButtonEnabled:focus::before {
  border-radius: 0px;
}
body .embeddedServiceHelpButton .uiButton .helpButtonLabel::before {
  content: "";
  background: url(https://media.restorationhardware.com/is/content/rhis/icons/ChatIcon.svg)
    no-repeat;
  background-size: contain;
  speak: none;
  margin-right: 10px;
  height: 18px;
  width: 18px;
  margin-bottom: 4px;
}

body .embeddedServiceHelpButton .helpButton .uiButton.helpButtonDisabled {
  display: none;
}

body.pf-global-popup-in .embeddedServiceHelpButton .helpButton {
  bottom: calc(80px + 30px);
}

/* Invite CSS */
body .embeddedServiceInvitation {
  position: fixed !important;
  top: auto !important;
  right: 0px !important;
  left: 0px !important;
  margin: 0px !important;
  width: 100% !important;
  max-width: 100% !important;
  z-index: 1002 !important;
  transition: bottom 1s ease;
  animation: none !important;
  /* Properties below are overridden once active state is toggled on */
  bottom: -1000px !important;
}
@media (min-width: 450px) {
  body .embeddedServiceInvitation {
    left: auto !important;
    right: 15px !important;
    width: 325px !important;
  }
  body .embeddedServiceHelpButton .helpButton {
    bottom: 40px;
  }
  body .embeddedServiceHelpButton .helpButton .uiButton .message::after {
    font-size: 12px;
  }
  body .embeddedServiceHelpButton .uiButton {
    min-width: 16.2em;
    max-width: 19em;
  }
  body .embeddedServiceHelpButton .helpButton .uiButton {
    font-size: 16px;
    height: 58px;
  }
}

/* This rule represents the active state - the inline animation property is unfortunately the only thing that can be hooked into. This is an ugly hack. */
body
  .embeddedServiceInvitation[style*="animation"]:not([inert]):not([aria-hidden="true"]) {
  bottom: 0px !important;
}

body .embeddedServiceInvitation {
  background-color: #fff;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
}
body .embeddedServiceInvitation > .embeddedServiceInvitationHeader {
  height: 25px;
  text-align: right;
}
body .embeddedServiceInvitationHeader #embeddedServiceAvatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
body .embeddedServiceInvitationHeader .embeddedServiceTitleText {
  font-size: 18px;
  color: #ffffff;
  overflow: hidden;
  word-wrap: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-self: stretch;
  flex-grow: 1;
  max-width: 100%;
  margin: 0 12px;
}
body .embeddedServiceInvitationHeader .embeddedServiceCloseIcon {
  border: none;
  padding: 18px 8px 30px 15px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  font-size: 44px;
  color: #000000;
  line-height: 0;
  font-family: "BaronSans-UltraThin";
}
body .embeddedServiceInvitationHeader .embeddedServiceCloseIcon:focus {
  outline: none;
}
body .embeddedServiceInvitationHeader .embeddedServiceCloseIcon:focus::before {
  content: " ";
  position: absolute;
  top: 11%;
  left: 7%;
  width: 85%;
  height: 85%;
  background-color: rgba(255, 255, 255, 0.2);
  pointer-events: none;
}
body .embeddedServiceInvitationHeader .embeddedServiceCloseIcon:active,
body .embeddedServiceCloseIcon:hover {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.7);
  opacity: 0.7;
}
body .embeddedServiceInvitation > .embeddedServiceInvitationBody {
  margin: 0 8px;
  font-size: 14px;
  line-height: 20px;
}
body .embeddedServiceInvitationBody h3 {
  color: #000000;
  padding: 3px;
  margin: 0;
  font-family: CaslonRHThin, Big Caslon, Palatino;
  text-align: center;
  font-weight: 100;
  font-size: 20px;
}
body .embeddedServiceInvitation > .embeddedServiceInvitationFooter {
  text-align: center;
}
body .embeddedServiceInvitationFooter > .embeddedServiceActionButton {
  font-size: 14px;
  padding: 16px 50px;
  margin: 12px 0 28px 0;
  cursor: pointer;
}
body .embeddedServiceInvitationFooter > #acceptInvite {
  border: 1px solid #d0d0d0;
  background-color: #ffffff;
  color: #000000;
  font-family: BaronSans-Medium;
  letter-spacing: 0.6px;
}
body .embeddedServiceInvitationFooter > #rejectInvite {
  background-color: #ffffff;
  color: #333333;
}
